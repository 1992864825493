import { ApolloError, gql as GraphQL, useQuery } from "@apollo/client";

export interface DataType {
    profile: IProfile;
    guilds: {
        included: { id: Snowflake; name: string; icon: string; owner: boolean; commands: number; modules: number }[];
        excluded: { id: Snowflake; name: string; icon: string }[];
    };
}

const QueryGraphQL = GraphQL`
    query getServerPageQuery {
        getProfile {
            _id
            settings {
                user {
                    username
                    discriminator
                    avatar
                }
            }
        }

        getGuild(guildReturn: "@mutual") {
            mutual {
                included {
                    id
                    name
                    icon
                    owner
                    commands
                    modules
                }
                excluded {
                    id
                    name
                    icon
                }
            }
        }
    }
`;

const Query = (): { loading: boolean; error: ApolloError | undefined; data: DataType | null; refetch: Function } => {
    const { loading, error, data, refetch } = useQuery(QueryGraphQL);

    if (error) console.log(error);

    if (loading || error) return { loading: true, error, data: null, refetch };

    return { loading: false, error, data: { profile: data.getProfile, guilds: data.getGuild.mutual }, refetch };
};

const Hook = { Query };

export default Hook;
